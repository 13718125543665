<script lang="ts" setup>
  interface IProps {
    main?: boolean;
  }
  const props = defineProps<IProps>();
</script>

<template>
  <component :is="props.main ? 'main' : 'div'" class="container" :class="{ main }">
    <slot />
  </component>
</template>
